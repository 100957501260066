<template>
  <section
    :class="[
      {
        'course__detail--section': !isAuthorized,
        'not-found': getPageNotFound
      }
    ]"
  >
    <div class="course__bg--wrapper">
      <div
        :class="{
          'container cd__wrapper fixed-container': !isAuthorized,
          'h-100': isAuthorized
        }"
      >
        <div
          :class="['cd__course-detail', { 'cd-top-section': !isAuthorized }]"
        >
          <div
            :class="['lx-breadcrumb', { 'd-none d-sm-block': isAuthorized }]"
          >
            <LxpBreadcrumb
              :items="setBreadcrumbsPath"
              @click="navigateToLink"
            />
          </div>
          <div class="cd-main__wrapper">
            <div
              class="cd-main__container"
              v-if="!isLoading && Object.keys(getSelectedCourse).length != 0"
            >
              <b-row class="m-0">
                <b-col cols="12" sm="12" md="12" lg="9" class="p-0">
                  <div class="cd__title--style" v-b-visible="visibleHandler">
                    {{ getSelectedCourse.title }}
                  </div>
                </b-col>
                <b-col
                  lg="3"
                  class="text-right cd-three-dots-menu--style d-none d-sm-block"
                  v-if="cStep === 2 && !isCourseEnded && isAuthorized"
                >
                  <!-- TODO: TO be replaced with didactica -->
                  <b-dropdown
                    right
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template #button-content>
                      <img
                        src="@/assets/images/vle/threedots.svg"
                        alt="threedots"
                      />
                    </template>

                    <b-dropdown-item class="unenroll" v-b-modal.unenroll-modal>
                      <div class="ac-mc__list">
                        <img
                          src="@/assets/images/icons/unenroll-module.svg"
                          alt="unenroll"
                          class="ac-mc__list-img"
                        />
                        {{ $t("course.unenroll.title") }}
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
              <div
                v-if="isAuthorized"
                class="selected-course-sections-hours d-none d-sm-flex"
              >
                <img
                  src="@/assets/images/courseware/modules.svg"
                  alt="modules"
                />
                <span class="cd__modules--style">
                  {{
                    getSelectedCourse.sections.length > 1
                      ? getSelectedCourse.sections.length
                      : ""
                  }}
                  {{
                    getSelectedCourse.sections.length > 1
                      ? $t("search.tab.modules")
                      : $t("course.x_modules_one")
                  }}
                </span>
                <img
                  v-if="getSelectedCourse.effort_time"
                  src="@/assets/images/courseware/time.svg"
                  alt="effort"
                />
                <span
                  v-if="getSelectedCourse.effort_time"
                  class="cd__effort--style"
                  :title="getSelectedCourse.effort_time"
                >
                  {{
                    $t("course.x_modules_hours", {
                      s: getSelectedCourse.effort_time
                    })
                  }}
                </span>
              </div>
              <ul class="course-info" @click="showCourseDetailModal" v-else>
                <li class="d-lg-none">
                  <img
                    src="@/assets/images/landing/course/language.svg"
                    alt="language"
                  />
                  <span :title="language" class="d-none d-md-block">
                    {{ $t(`data.language.${language}`) }}
                  </span>
                  <span
                    :title="language"
                    class="d-block d-md-none text-uppercase"
                  >
                    {{ language }}
                  </span>
                </li>
                <li>
                  <img
                    src="@/assets/images/landing/course/modules.svg"
                    alt="modules"
                  />
                  <span :title="allSequences.length">
                    {{ getSelectedCourse.sections.length }}
                    <span class="d-none d-md-block">
                      &nbsp;{{ $t("search.tab.modules") }}
                    </span>
                  </span>
                </li>
                <li v-if="getSelectedCourse.hours_of_effort">
                  <img
                    src="@/assets/images/landing/course/time.svg"
                    alt="time"
                  />
                  <span
                    :title="getSelectedCourse.hours_of_effort"
                    class="d-none d-md-block"
                  >
                    {{
                      $t("course.x_modules_hours", {
                        s: getSelectedCourse.hours_of_effort
                      })
                    }}
                  </span>
                  <span
                    :title="getSelectedCourse.hours_of_effort"
                    class="d-block d-md-none"
                  >
                    {{
                      $t("course.x_modules_hours_mobile", {
                        s: getSelectedCourse.hours_of_effort
                      })
                    }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="{
        'container cd__wrapper fixed-container': !isAuthorized,
        'h-100': isAuthorized
      }"
      :id="isAuthorized ? 'qa-course-detail-view' : ''"
    >
      <div
        :class="['cd__course-detail', { 'cd-bottom-section': !isAuthorized }]"
      >
        <div class="cd-main__wrapper">
          <Shimmer v-if="isLoading"></Shimmer>
          <div
            class="cd-main__container"
            v-else-if="!isLoading && Object.keys(getSelectedCourse).length != 0"
          >
            <div
              :class="{
                'd-flex': isAuthorized,
                'cd-video-section': !isAuthorized
              }"
            >
              <div
                :class="{
                  'cd-main_course-content': isAuthorized,
                  row: !isAuthorized
                }"
              >
                <div
                  :class="{
                    'mb-3 col-md-12 col-lg-9 left-panel': !isAuthorized
                  }"
                >
                  <div v-if="isAuthorized" class="cd__prg-overview__banner">
                    <b-img
                      fluid-grow
                      center
                      class="cd__overview--style"
                      :src="placeholderImage || getSelectedCourse.image_url"
                      :alt="getSelectedCourse.title"
                      @error="handleError"
                    ></b-img>
                    <div
                      class="intro_video_url_sm d-block d-lg-none"
                      v-if="getSelectedCourse.intro_video_url"
                      :style="{
                        'background-image':
                          'url(' + getSelectedCourse.image_url + ')'
                      }"
                    >
                      <img
                        v-if="getSelectedCourse.intro_video_url"
                        src="@/assets/images/icons/play-blue-title.svg"
                        alt="play"
                        width="56px"
                        height="56px"
                        class="c-pointer"
                        @click="$bvModal.show('intro-video-modal')"
                      />
                    </div>
                  </div>

                  <div v-else class="cd__prg-overview__banner">
                    <div
                      class="video-overlay"
                      v-if="
                        getSelectedCourse.intro_video_url && isPlayIconVisible
                      "
                    >
                      <img
                        src="@/assets/images/landing/course/play.svg"
                        alt="play"
                        width="56px"
                        height="56px"
                        class="play-icon c-pointer"
                        @click="hidePlayIcon(false)"
                      />
                      <img
                        :src="youtubeThumbnail"
                        alt="video thumbnail"
                        class="cd__overview--style"
                      />
                    </div>
                    <iframe
                      class="course-video"
                      frameBorder="0"
                      :src="getSelectedCourse.intro_video_url"
                      title="Course Enroll"
                      v-if="getSelectedCourse.intro_video_url"
                    ></iframe>
                    <img
                      :src="placeholderImage || getSelectedCourse.image_url"
                      @error="handleError"
                      alt="empty"
                      width="auto"
                      height="auto"
                      class="cd__overview--style"
                      v-else
                    />
                  </div>

                  <div v-if="isAuthorized">
                    <b-row
                      class="selected-course-sections-hours d-block d-sm-none"
                    >
                      <b-col lg="12">
                        <div class="d-flex justify-content-around py-3">
                          <div>
                            <img
                              src="@/assets/images/courseware/modules-mobile.svg"
                              alt="modules"
                            />
                            <span
                              class="selected-course-sections"
                              :title="allSequences.length"
                            >
                              {{ getSelectedCourse.sections.length }}
                            </span>
                          </div>
                          <div v-if="getSelectedCourse.hours_of_effort">
                            <img
                              src="@/assets/images/courseware/time-mobile.svg"
                              alt="effort"
                            />
                            <span
                              class="selected-course-sections"
                              :title="getSelectedCourse.hours_of_effort"
                            >
                              {{ getSelectedCourse.hours_of_effort }}
                            </span>
                          </div>
                          <div>
                            <span
                              class="c-pointer"
                              @click="$bvModal.show('course-details-modal')"
                            >
                              <img
                                src="@/assets/images/layout/user-fluid-layout/language.svg"
                                alt="language"
                              />
                              <span
                                class="selected-course-modules-hours text-uppercase"
                                :title="language"
                              >
                                {{ language }}
                              </span>
                            </span>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div
                    class="d-lg-none"
                    v-if="
                      legacyContent &&
                        getSelectedCourse.short_description &&
                        isAuthorized
                    "
                  >
                    <span class="cd__short-desc--resp">
                      {{ formattedBody }}
                    </span>
                    <span
                      class="cd__short-desc--more"
                      @click="showingFullText = !showingFullText"
                    >
                      See {{ showingFullText ? "less" : "more" }}
                    </span>
                  </div>
                  <div
                    class="d-flex justify-content-between self-paced-calender-icons border-bottom d-lg-none"
                  >
                    <div class="text-center">
                      <div>
                        <img
                          src="@/assets/images/courseware/self-paced-blue.svg"
                          alt="self-paced"
                        />
                      </div>
                      <div class="title">
                        {{ $t(`data.pacing.${getSelectedCourse.pacing}`) }}
                      </div>
                      <div class="description">
                        {{ $t("course_detail.hundred_percent_online") }}
                      </div>
                    </div>
                    <div class="text-center">
                      <div>
                        <img
                          src="@/assets/images/courseware/calendar-blue.svg"
                          alt="calendar"
                        />
                      </div>
                      <div class="title">
                        {{ $t("course_detail.starting") }}
                      </div>
                      <div class="description">
                        {{
                          getSelectedCourse.course_starts_at
                            | formatDate("MMM Do YYYY")
                        }}
                      </div>
                    </div>
                    <div class="text-center">
                      <div>
                        <img
                          src="@/assets/images/courseware/end-date-calendar.svg"
                          alt="calendar"
                        />
                      </div>
                      <div class="title">
                        {{ $t("course_detail.ending") }}
                      </div>
                      <div class="description">
                        {{
                          getSelectedCourse.course_ends_at
                            | formatDate("MMM Do YYYY")
                        }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="cd-tabs-custom-styles"
                    :class="{ 'cd-body-section': !isAuthorized }"
                  >
                    <b-tabs pills content-class="mt-4" v-model="tabIndex" lazy>
                      <b-tab :title="$t('course.tab.course_overview')" active>
                        <div
                          class="cd-sec__des d-none d-lg-block"
                          v-if="
                            legacyContent && getSelectedCourse.short_description
                          "
                        >
                          {{ getSelectedCourse.short_description }}
                        </div>
                        <div
                          v-if="
                            legacyContent && getSelectedCourse.short_description
                          "
                        >
                          <hr
                            class=" cd__short-desc--style d-none d-lg-block"
                          />
                        </div>
                        <div class="cd-content__overview">
                          <div v-if="legacyContent">
                            <div
                              :class="{
                                'cd-overview__content': true,
                                'cd-intro': getSelectedCourse.intro_video_url
                              }"
                              v-html="getSelectedCourse.overview"
                            ></div>
                          </div>
                          <div v-else-if="overviewContent">
                            <CourseOverview
                              :key="key"
                              :isEdit="false"
                              :overviewContent="overviewContent"
                              :courseId="courseId"
                              :courseTitle="getSelectedCourse.title"
                            />
                          </div>
                        </div>
                      </b-tab>
                      <b-tab :title="$t('search.tab.modules')">
                        <div class="sp__left-modules course-details-modules">
                          <ModuleList
                            :module="m"
                            v-for="(m, i) in modules"
                            :key="i"
                            :currentModuleNo="i + 1"
                            :totalModules="modules.length"
                            :isOpen="m.isOpen"
                            :isEnable="
                              isAuthorized
                                ? getSelectedCourse.is_enrolled &&
                                  !hasFutureStartDate
                                : false
                            "
                          />
                        </div>
                      </b-tab>
                    </b-tabs>
                  </div>
                  <div v-if="showSummary" class="cd_org_details">
                    <b-row>
                      <b-col class="cd_org_card__header_l">
                        {{ $t("course.designed_and_developed_by") }}
                      </b-col>
                      <b-col
                        v-if="spaceInfo.terms_and_conditions"
                        class="cd-org__card_header_r"
                      >
                        <div>
                          <a
                            target="blank"
                            :href="spaceInfo.terms_and_conditions"
                            >{{ $t("course_detail.terms_conditions") }}</a
                          >
                        </div>
                      </b-col>
                    </b-row>
                    <b-row class="cd_org-card">
                      <b-col class="cd_org-col" cols="12">
                        <div class="ls-card__wrapper" @click="onManageClick">
                          <div class="ls-card__left">
                            <img
                              :src="spaceInfo.logo"
                              alt="logo"
                              width="128"
                              height="72"
                            />
                          </div>
                          <div class="ls-card__right">
                            <h6>{{ spaceInfo.name }}</h6>
                            <p>{{ spaceInfo.short_headline }}</p>
                            <div class="ls-card__footer">
                              <div
                                class="ls-card__footer-view"
                                @click="onManageClick"
                              >
                                <span>{{ $t("course_detail.view_more") }}</span>
                                <img
                                  src="@/assets/images/module-list/arrow-right.svg"
                                  alt="edit"
                                />
                              </div>
                            </div>
                          </div></div
                      ></b-col>
                    </b-row>
                    <b-row>
                      <b-col
                        v-if="spaceInfo.terms_and_conditions"
                        class="cd_org_terms__mobile"
                        cols="12"
                      >
                        <div>
                          <a
                            target="blank"
                            :href="spaceInfo.terms_and_conditions"
                            >{{ $t("course_detail.terms_conditions") }}</a
                          >
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                  <div class="pt-2">
                    <b-row>
                      <b-col cols="12" sm="12" md="12" lg="12">
                        <div
                          class="more-learning-programs"
                          v-if="recommendedCourses.length"
                        >
                          <DashboardHeading
                            iconFileName="more-learning-programs-yellow-icon.svg"
                          >
                            {{
                              $t(
                                "course_discovery.heading.more_learning_programmes"
                              )
                            }}
                          </DashboardHeading>
                          <div class="mt-4">
                            <RecommendedCourses
                              :isLoading="isLoading"
                              :courses="recommendedCourses"
                              @click="onCard"
                            />
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <div
                  v-if="!isAuthorized"
                  class="mb-3 col-md-3 text-right d-none d-lg-block right-panel"
                >
                  <Actions
                    :isTitleDisplay="isTitleDisplay"
                    :getSelectedCourse="getSelectedCourse"
                    :language="language"
                    :allConfig="allConfig"
                    :cStep="1"
                    :isEnrollEnabled="isEnrollEnabled('pre')"
                    :hasFutureStartDate="hasFutureStartDate"
                    :hasFutureEnrollmentDate="hasFutureEnrollmentDate"
                    :hasPassedEnrollmentDate="hasPassedEnrollmentDate"
                    :tooltipData="tooltipData"
                    :enrollSoonTooltipData="enrollSoonTooltipData"
                  />
                </div>
              </div>
              <!-- Actions component -->
              <div
                v-if="isAuthorized"
                class="cd-main_course-actions text-right d-none d-lg-block"
              >
                <Actions
                  :isTitleDisplay="isTitleDisplay"
                  :getSelectedCourse="getSelectedCourse"
                  :language="language"
                  :allConfig="allConfig"
                  :cStep="cStep"
                  :hasFutureEnrollmentDate="hasFutureEnrollmentDate"
                  :isEnrollEnabled="isEnrollEnabled()"
                  :enrollSoonTooltipData="enrollSoonTooltipData"
                  :startingSoonTooltipData="startingSoonTooltipData"
                  :reviewTooltipData="reviewTooltipData"
                  :tooltipData="tooltipData"
                  :hasPassedEnrollmentDate="hasPassedEnrollmentDate"
                  :hasFutureStartDate="hasFutureStartDate"
                  :isCourseEnded="isCourseEnded"
                  @onEnroll="onEnroll"
                />
              </div>
            </div>
          </div>
          <div
            class="d-flex align-items-center justify-content-center h-100"
            v-else
          >
            <PageNotFound
              :description="$t('error.page_not_found.subtitle')"
              :exploreButton="false"
            />
          </div>
        </div>
        <div
          v-if="isAuthorized"
          class="cd-fixed-footer-section d-block d-lg-none"
        >
          <Actions
            :isTitleDisplay="isTitleDisplay"
            :getSelectedCourse="getSelectedCourse"
            :language="language"
            :allConfig="allConfig"
            :cStep="cStep"
            :hasFutureEnrollmentDate="hasFutureEnrollmentDate"
            :isEnrollEnabled="isEnrollEnabled()"
            :enrollSoonTooltipData="enrollSoonTooltipData"
            :startingSoonTooltipData="startingSoonTooltipData"
            :reviewTooltipData="reviewTooltipData"
            :tooltipData="tooltipData"
            :hasPassedEnrollmentDate="hasPassedEnrollmentDate"
            :hasFutureStartDate="hasFutureStartDate"
            :isCourseEnded="isCourseEnded"
            @onEnroll="onEnroll"
          />
        </div>
        <div
          v-else
          id="cd-footer-section"
          class="cd-fixed-footer-section d-block d-lg-none"
        >
          <div class="footer-container">
            <LxpButton
              variant="primary"
              :class="['btn btn-md', !isAuthorized ? 'gradient-bg' : '']"
              :pill="true"
              :block="true"
              @click="startLearning"
            >
              <img
                src="@/assets/images/courseware/enroll-white.svg"
                alt="enroll"
                :class="{
                  'mr-2': language !== 'ar',
                  'ml-2': language === 'ar'
                }"
                width="auto"
                height="auto"
              />{{ $t("course.enroll_now") }}
            </LxpButton>
            <div class="cd__register-text">
              <a class="cd__register--link" href="javascript:;" @click="signUp">
                {{ $t("general.register") }}
              </a>
              <span class="cd__start-learning">{{
                $t("general.to_start_learning")
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <unenroll-modal :courseId="this.getSelectedCourse.id"></unenroll-modal>
      <!-- Modals -->
      <CourseDetailModals></CourseDetailModals>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import utils from "@/router/utils";
import { mapGetters, mapState } from "vuex";
import { LxpBreadcrumb, LxpButton } from "didactica";
import { CourseOverview } from "didactica";
import moment from "moment";
import COMMAN_MODAL_IDS from "@/components/Shared/CommonModalConstants";
import RecommendedCourses from "@/components/Dashboard/RecommendedCourses.vue";
// wrapper components
import Shimmer from "@/components/WrapperComponents/Shimmer.vue";
import UnenrollModal from "@/components/CourseDetail/Modals/UnenrollModal.vue";
// child components
import Actions from "@/components/CourseDetail/Actions.vue";
import ModuleList from "@/components/CourseDetail/ModulesTabContent.vue";
import CourseDetailModals from "@/components/CourseDetail/Modals/CourseDetailModals.vue";
import PageNotFound from "./PageNotFound.vue";

//mixins
import courseDetailMixin from "@/components/CourseDetail/mixins/courseDetail";
import redirectToLearningSpace from "@/core/mixins/redirectToLearningSpace";
import ctaActionsMixin from "@/components/CourseDetail/mixins/ctaActions";
export default {
  components: {
    Shimmer,
    Actions,
    RecommendedCourses,
    ModuleList,
    UnenrollModal,
    CourseDetailModals,
    LxpBreadcrumb,
    LxpButton,
    CourseOverview,
    PageNotFound
  },
  mixins: [courseDetailMixin, redirectToLearningSpace, ctaActionsMixin],
  props: {
    isAuthorized: Boolean
  },
  data() {
    return {
      breadcrumbs: [
        {
          icon: require("@/assets/images/courseware/learning-interest.svg"),
          text: this.$i18n.t("course.breadcrumb.course"),
          to: { name: "course-discovery" }
        },
        {
          icon: null,
          text: "",
          active: true
        }
      ],
      isLoading: true,
      max: 100,
      scTimer: 0,
      showingFullText: false,
      spaceInfo: {
        logo: "",
        name: "",
        short_headline: "",
        terms_and_conditions: ""
      },
      legacyContent: false,
      key: "",
      showSummary: true,
      isOpen: false,
      isVideoView: false,
      isPlayIconVisible: true,
      isMobile: false
    };
  },
  computed: {
    ...mapGetters([
      "getAuthProfile",
      "allConfig",
      "getCourseOverview",
      "getPageNotFound"
    ]),
    ...mapState(["course"]),

    overviewContent() {
      return this.getCourseOverview;
    },
    selectedCourseOrg() {
      return this.$store.state.selectedCourse.org;
    },
    formattedBody() {
      if (this.showingFullText) {
        return this.getSelectedCourse.short_description;
      } else {
        return this.getSelectedCourse.short_description
          ? `${this.getSelectedCourse.short_description
              .slice(0, 100)
              .trim()}...`
          : null;
      }
    },

    cStep() {
      if (this.isAuthorized) {
        let step = 1;
        if (!this.getSelectedCourse.is_enrolled) {
          step = 1;
        }
        if (this.getSelectedCourse.is_enrolled && !this.isCourseStarted) {
          step = 2;
        }
        if (this.getSelectedCourse.is_enrolled && this.isCourseStarted) {
          step = 3;
        }
        if (this.getSelectedCourse.course_completed) {
          step = 4;
        }
        if (
          this.getSelectedCourse.is_enrolled &&
          !this.getSelectedCourse.course_completed &&
          this.isCourseEnded
        ) {
          step = 5;
        }

        return step;
      } else {
        return null;
      }
    },

    startingSoonTooltipData() {
      if (this.isAuthorized) {
        let date = this.getSelectedCourse.course_starts_at
          ? moment(this.getSelectedCourse.course_starts_at).format("DD/MM/YY")
          : "";
        return `${this.$i18n.t("course.scheduled_to_start") +
          " " +
          date +
          "."}`;
      } else {
        return null;
      }
    },

    reviewTooltipData() {
      if (this.isAuthorized) {
        return `${this.$i18n.t("course.review_closed_alert.message")}`;
      } else {
        return null;
      }
    },

    setBreadcrumbsPath() {
      var courseTitle = "";

      if (
        this.getSelectedCourse !== undefined &&
        this.getSelectedCourse.title !== undefined
      ) {
        let label = this.getSelectedCourse.title;
        if (label.length <= 30) {
          courseTitle = label;
        } else {
          courseTitle = label.slice(0, 30) + " ...";
        }
      }
      if (!this.isAuthorized) {
        return [
          {
            icon: null,
            text: this.$i18n.t("general.menu.home"),
            to: { name: "Home" }
          },
          {
            icon: null,
            text: this.$i18n.t("general.menu.discovery"),
            to: { name: "discovery" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "learning-programmes") {
        return [
          {
            icon: require("@/assets/images/courseware/school-grey.svg"),
            text: this.$i18n.t("general.menu.my_learning"),
            to: { name: "my-learning" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "home-courses") {
        return [
          {
            icon: require("@/assets/images/courseware/dashboard.svg"),
            text: this.$i18n.t("general.menu.home"),
            to: { name: "Dashboard" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "discovery") {
        return [
          {
            icon: require("@/assets/images/menu/library-discovery.svg"),
            text: this.$i18n.t("general.menu.discovery"),
            to: { name: "course-discovery" }
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      } else if (this.$route.query.from === "learning-space") {
        const courseOrg = this.$route.query.org.toUpperCase();
        return [
          {
            icon: require("@/assets/images/menu/library-discovery.svg"),
            text: this.$i18n.t("general.menu.discovery"),
            to: { name: "course-discovery" }
          },
          {
            text: courseOrg,
            to: { name: "LearningSpace", params: { org: courseOrg } },
            active: false
          },
          {
            icon: null,
            text: courseTitle,
            active: true
          }
        ];
      }
      return [
        {
          icon: require("@/assets/images/courseware/learning-interest.svg"),
          text: this.$i18n.t("general.menu.discovery"),
          to: { name: "course-discovery" }
        },
        {
          icon: null,
          text: courseTitle,
          active: true
        }
      ];
    },
    authToken() {
      if (this.isAuthorized) {
        return `Bearer ${this.$keycloak.token}`;
      } else {
        return null;
      }
    },
    currentOrg() {
      if (this.isAuthorized) {
        return this.$route.params.org;
      } else {
        return null;
      }
    }
  },
  created() {
    if (this.isAuthorized) {
      // Reset the session cookies
      this.$store.commit("SET_SESSION_COOKIE", []);
    } else {
      if (document.getElementsByTagName("BODY")[0].classList.length === 0) {
        document.getElementsByTagName("BODY")[0].style.overflow = "auto";
      }
      window.scrollTo(0, 0);
    }
  },

  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize);
    if (this.isAuthorized) {
      // init after importing the layout
      if (this.isUserFluidLayoutLoaded()) {
        this.$nextTick(async () => {
          this.getCourseDetail();
          this.getRandomCourse(3);
        });
      }
    } else {
      this.getACourse();
      this.getRandomCourse(3);
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getACourse() {
      this.$store
        .dispatch("getAPublicCourse", {
          course_id: this.$route.params.id
        })
        .then(() => {
          this.isLoading = false;
          this.getCourseOverviewData();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    handleError() {
      this.placeholderImage = require("@/assets/images/illustration/course-detail-illustration.svg");
    },
    onManageClick() {
      if (this.isAuthorized) {
        this.navigateToLearningSpace(this.selectedCourseOrg);
      } else {
        this.signUp();
      }
    },
    signUp() {
      this.$keycloak.login({
        redirectUri: this.redirectUri,
        action: "register",
        locale: this.language
      });
    },
    async getRandomCourse(count) {
      this.recommendedCourses = await this.$store.dispatch(
        "getRandomCourses",
        count
      );
    },
    playVideo() {
      this.isVideoView = true;
    },
    startLearning() {
      this.$keycloak.login({
        redirectUri: utils.getRedirectUri(),
        locale: this.language
      });
    },
    onToggle() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.$bvModal.show("course-details-modal");
        document.body.style.overflow = "hidden";
        document.getElementById("cd-footer-section").style.zIndex = "-1";
      } else {
        document.body.style.overflow = "auto";
        document.getElementById("cd-footer-section").style.zIndex = "9999";
      }
    },
    getLearningSpaceSummary() {
      axios
        .get(
          `${this.allConfig.baseURL}/spaces/${this.selectedCourseOrg}/summary/`
        )
        .then(res => {
          this.spaceInfo = res.data;
          this.key++;
          this.isLoading = false;
        })
        .catch(() => {
          this.showSummary = false;
          this.isLoading = false;
        });
    },

    getCourseDetail() {
      this.$store
        .dispatch("getACourse", {
          course_id: this.$route.params.id,
          version: "v1"
        })
        .then(() => {
          this.isLoading = false;
          this.getCourseOverviewData();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    getCourseOverviewData() {
      this.$store
        .dispatch("getCourseOverview", {
          course_id: this.$route.params.id
        })
        .then(() => {
          this.getLearningSpaceSummary();
        })
        .catch(() => {
          this.getLearningSpaceSummary();
          this.legacyContent = true;
          this.hasError = true;
        });
    },

    onEnroll(step) {
      if (!this.isAuthorized) {
        this.signUp();
      } else {
        this.cLearningStep = step;
        if (this.getAuthProfile.is_mfa_enabled) {
          if (step === 3) {
            // reset the current menu to modules, just in case if the learner
            // left the self-paced course from teams page
            this.$store.commit("SET_CURRENT_MENU_ITEM", "modules");
            this.$router
              .push({
                path: `/self-paced/${this.$route.params.id}`,
                query:
                  this.$route.query.from === "learning-programmes"
                    ? { from: "learning-programmes" }
                    : this.$route.query.from === "home-courses"
                    ? { from: "home-courses" }
                    : {}
              })
              .catch(() => {});
          } else if (step === 2) {
            this.$store
              .dispatch("enrollCourse", this.$route.params.id)
              .then(() => {
                this.$store.dispatch("getACourse", {
                  course_id: this.$route.params.id,
                  version: "v1"
                });
              })
              .catch(() => {});
          }
        } else {
          this.$bvModal.show(COMMAN_MODAL_IDS.MFA_CONFIGURE_MODAL);
        }
      }
    },
    enrollSuccessModalClose() {
      this.$router.push({
        name: "Self Paced",
        params: { id: this.$route.params.id }
      });
    },
    navigateToLink(item) {
      this.$router.push(item.to);
    },
    hidePlayIcon(value) {
      this.isPlayIconVisible = value;
    },
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
    showCourseDetailModal() {
      if (this.isMobile) {
        this.$bvModal.show("course-details-modal");
      }
    }
  }
};
</script>
<style lang="scss">
@import "../scss/course-details/typography";
@import "../scss/course-details/modals";

.cd-main_course-content {
  width: calc(100% - 237px);
}
.cd-main_course-actions {
  margin-left: 16px;
  .cd__actions-wrapper {
    width: 260px;
  }
}

.ac-mc__list {
  color: $brand-error-400;
  .ac-mc__list-img {
    margin-right: 4px;
  }
}
.cd__title--style {
  @include h3;
  margin-bottom: 24px;
  word-break: break-word;
}
.cd__modules--style {
  @include course-header;
  padding-right: 28px;
}
.cd__effort--style {
  @include course-header;
}
.cd__prg-overview__banner {
  border-radius: 25px;
  overflow: hidden;
  height: 354px;
}
.cd-fixed-footer-section {
  position: fixed;
  bottom: 0px;
  padding: 10px;
  padding-top: 20px;
  background: $brand-primary-50;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  z-index: 9999;

  .lp-button__height {
    padding-top: 16px;
    padding-bottom: 16px;
    border-color: transparent !important;
    background: $brand-primary;
    width: 100%;
    margin-bottom: 5px;
  }
}
.cd__overview--style {
  width: 100%;
  max-height: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.cd__short-desc--style {
  background: $brand-primary-50;
  padding: 0px;
  gap: 16px;
}
.cd__short-desc--resp {
  @include course-body;
  align-items: center;
  color: $brand-neutral-700;
  word-break: break-word;
}
.cd__short-desc--more {
  @include course-body;
  align-items: center;
  color: $brand-primary-400;
  text-decoration: underline;
}
.cd__who-stamp--style {
  text-align: left;
  padding-left: 15px;
  padding-top: 5px;
}
.cd__enroll--alert-title {
  @include subtitle-large;
  letter-spacing: 0.0025em;
  color: $brand-neutral-900;
  padding-top: 18px;
  padding-bottom: 8px;
}
.cd__enroll--alert-message {
  @include body-regular;
  margin-bottom: 16px;
  padding: 0 6px;
}
.cdp__who-stamp--message {
  @include subtitle-regular;
  color: $brand-neutral-900;
}
.cdp__who-stamp--message2 {
  @include body-medium;
  color: $brand-neutral-900;
  padding-top: 10px;
}
.cd-main__wrapper {
  background: $brand-primary-50;
  border-radius: 4px;
  box-sizing: border-box;
  .lx-shimmer {
    margin-top: 20px;
  }
}
.cd-tabs-custom-styles {
  padding: 16px 0;
  .nav-item + .nav-item {
    margin-left: 16px;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: $brand-primary-400;
    background: $brand-primary-100 !important;
  }
  .nav-pills .nav-link {
    border-radius: 8px;
    color: $brand-neutral-700;
    @include button-label;
  }
}
.lds-ripple {
  background: #f7f8fb;
}

.lp-wrapper {
  background: #f9fcff !important;
}
.cd-three-dots-menu--style {
  .dropdown-menu {
    box-shadow: 0px 1px 3px 1px rgb(33 33 33 / 25%);
    border-radius: 8px;
    border: none;
    position: absolute;
    top: 10px;
  }
  button {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 100px;
    img {
      position: absolute;
      left: 41.67%;
      right: 41.67%;
      top: 16.67%;
      bottom: 16.67%;
    }
    &:hover {
      background: $brand-primary-100;
    }
  }
}
.cd__course-detail {
  padding: 25px 52px 0px;
  background-color: $brand-primary-50;
  .lx-breadcrumb {
    padding: 0;
    .breadcrumb {
      padding: 0;
      margin: 28px 0;
    }
  }
  .selected-course-sections-hours {
    margin: 0 0 28px 2px;
  }
  h5 {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: #0043ac;
  }
}
#qa-course-detail-view {
  .cd__course-detail {
    padding: 0px 52px 18px;
  }
}
.intro_video_url {
  height: 123px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 1px;
}
.cd-overview__intro {
  margin-top: 3%;
  background: #0043ac;
  display: flex;
  align-items: center;
  color: $brand-neutral-0;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  p {
    margin: 0 0 0 10px;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: rgba(255, 255, 255, 0.87);
  }
}
.cd-main__container {
  text-align: left;

  .cd-main__title {
    padding: 0;
    .cd-main__heading {
      margin: 0;
      height: 350px;
      background-size: 100%;
      background-position-y: 50%;
      .cd-main__bg {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.72) 100%
        );
      }
      .col-12 {
        display: flex;
        align-items: flex-end;
        padding-left: 25px;
        div {
          * {
            color: $brand-neutral-0;
          }
          .cd-main__content {
            .progress-bar {
              background-color: #0043ac;
            }
            .cd-main__p {
              letter-spacing: 0.1px;
              font-weight: bold;
              font-size: 14px;
              line-height: 14px;
              color: $brand-neutral-0;
              margin-right: 8px;
              &.first {
                &::after {
                  content: "";
                  width: 4px;
                  height: 4px;
                  background: $brand-neutral-0;
                  border-radius: 100%;
                  display: inline-block;
                  margin: 0 0px 2px 8px;
                }
              }
            }
          }
        }
      }
    }
    .cd-title__cta {
      padding: 3% 2%;
      .cd-cta__div {
        position: relative;
        .cd-cta__content {
          color: #0043ac;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.1px;
        }
        .cd-cta__absolute {
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .cd-content {
    .cd-content__sec {
      &::-webkit-scrollbar {
        display: none;
      }
      .ld-sec__tabs {
        margin: 0px;
        height: 100%;
        .tabs {
          height: 100%;
          .tab-content {
            .tab-pane {
              height: 100%;
            }
          }
        }
      }
    }
    .cd-content__sec,
    .cd-author__sec {
      h5 {
        margin-bottom: 20px;
        padding: 0 3%;
      }
      .cd-content__discussion-wrapper {
        padding: 0 2%;
        height: 100%;
      }
      .cd-content__overview {
        .cd-overview__intro {
          margin-top: 3%;
          background: #0043ac;
          display: flex;
          align-items: center;
          color: $brand-neutral-0;
          padding: 15px;
          border-radius: 4px;
          cursor: pointer;
          p {
            margin: 0 0 0 10px;
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.2px;
            color: rgba(255, 255, 255, 0.87);
          }
        }
      }
      .cd-content__bfstart {
        margin: 0 8% 15px 4%;
        background: rgba(0, 67, 172, 0.15);
        padding: 12px 15px;
        border-radius: 4px;
        h5 {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.87);
          margin-bottom: 6px;
          padding-left: 0;
          font-weight: 500;
        }
        .cd-bfstart__des {
          display: flex;
          align-items: flex-start;
          p {
            font-size: 16px;
            color: $brand-secondary-text;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .cd-certificate {
    background: $brand-neutral-0;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 4%;
    .media-body {
      h5 {
        font-weight: 500;
        font-size: 32px;
        line-height: 42px;
        letter-spacing: 0.1px;
        color: #0043ac;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.1px;
        color: $brand-secondary-text;
      }
      .ob-btn__pill {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: $brand-neutral-0;
        background: #0043ac;
        border: none;
        margin-right: 10px;
      }
    }
  }
  .cd-review {
    #custom-controls {
      top: 45%;
      .text-right {
        right: 5px;
      }
      .text-left {
        left: -30px;
      }
      > div {
        font-size: 12px;
      }
    }
    .cd-review__avg {
      h3 {
        font-weight: bold;
        font-size: 50px;
        line-height: 59px;
        letter-spacing: 1.25px;
        color: #0043ac;
        margin-bottom: 0;
      }
      p {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: $brand-secondary-text;
        margin-top: 4px;
      }
    }
  }
}
.more-learning-programs {
  border-top: 1px solid #acacac;
  padding: 48px 0 76px;
  margin-top: 18px;
  .lx-course-card--div {
    max-width: 100%;
  }
}
.cd-tabs-custome-styles {
  padding: 24px 0;
  .nav-item + .nav-item {
    margin-left: 16px;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: $brand-primary-400;
    background: $brand-primary-100 !important;
  }
  .nav-pills .nav-link {
    border-radius: 8px;
    color: $brand-neutral-700;
    @include dashboard-label;
  }
}
@media screen and (max-width: 512px) {
  .nav-link {
    padding: 8px 10px;
    font-size: 14px !important;
  }
  .cd__more-learning-programs {
    margin-top: 18px !important ;
  }

  .cd_org-card {
    .ls-card__wrapper {
      display: flow;
    }
  }
}
.cd-fixed-footer-section {
  position: fixed;
  bottom: 0px;
  right: 0;
  background: $brand-neutral-0;
  width: 100%;
  border-radius: 16px 16px 0px 0px;
  z-index: 9999;
  .lp-button__height {
    padding-top: 16px;
    padding-bottom: 16px;
    border-color: transparent !important;
    background: $brand-primary;
    width: 100%;
    margin-bottom: 5px;
  }
}
.cd-sec__des {
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  word-break: break-word;
  color: $brand-public-secondary;
}
.lx-breadcrumb {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: $brand-neutral-200;
}
.close-icon--styles {
  right: 20px;
  top: 20px;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  position: absolute;
  cursor: pointer;
  border-radius: 16px;
  z-index: 1;
}

.unenroll {
  .dropdown-item:hover {
    background: $brand-error-100;
  }

  .dropdown-item:focus {
    background: $brand-error-100;
  }

  .dropdown-item:active {
    background: $brand-error-100;
  }
}

.sticky-main {
  position: sticky;
  position: -webkit-sticky;
  top: 32px;
  width: 100%;
  &.pre-login {
    top: 74px;
  }
}
.system-alert {
  .sticky-main {
    &.pre-login {
      top: 114px;
    }
  }
}

.cd__tooltip--text {
  @include label-large;
  color: $brand-primary-75 !important;
  margin-bottom: 0;
  text-align: left;
}
.cd__cm__body--image {
  width: 34px;
  height: 34px;
  margin-top: -3.5rem;
}
@media screen and (max-width: 991px) {
  .sticky-main {
    width: 100% !important;
  }

  .cd__prg-overview-wrapper h3,
  h2 {
    @include h5;
    color: $brand-secondary-text;
    margin-bottom: 24px;
  }
  .response h3,
  h2 {
    @include h5;
    color: $brand-secondary-text;
    margin-bottom: 24px;
  }
  .teacher-image img {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }
  .teacher h3 {
    @include subtitle-regular;
    display: flex;
    align-items: center;
    color: $brand-neutral-900;
    margin-bottom: 0;
  }
  .cd__course-detail {
    padding: 0 16px;
  }
  .nav-link {
    padding: 8px 10px;
  }
  .cd__who-stamp--style {
    padding: 0;
    text-align: center;
  }
  .cd__who-stamp-content--style {
    padding: 0;
  }
  .cd-tabs-custome-styles {
    .nav-item + .nav-item {
      margin-left: 10px;
    }
  }
  // .more-learning-programs {
  //   padding-bottom: 120px !important;
  // }
  #qa-course-detail-view {
    .cd-main_course-content {
      width: 100%;
    }
    .cd__prg-overview__banner {
      position: relative;

      .intro_video_url_sm {
        border-radius: 24px 24px 24px 24px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
@media all and (min-width: 990px) and (max-width: 1230px) {
  .cd__course-detail {
    .cd-author__sec {
      .cd-author__name {
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
    .cd-main__container {
      .cd-certificate {
        padding: 4% 0% 4% 2%;
        .media-body {
          h5 {
            font-size: 28px;
          }
          .ob-btn__pill {
            font-size: 9px;
            padding: 3px 8px;
            border-radius: 12px !important;
          }
        }
      }
    }
  }
}
.cd-top-section,
.cd-bottom-section {
  .selected-course-sections-hours {
    text-align: left;
    .selected-course-sections {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.2px;
      padding-top: 4px;
      padding-right: 28px;
      padding-left: 4px;
    }
    .selected-course-modules-hours {
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.2px;
      padding-top: 4px;
      padding-left: 4px;
    }
  }
  .self-paced-calender-icons {
    img {
      filter: invert(35%) sepia(36%) invert(37%) sepia(58%) saturate(5230%)
        hue-rotate(198deg) brightness(104%) contrast(104%);
    }
  }
}
.cd-body-section {
  margin-top: 24px;

  .cd-button__mobile {
    margin-bottom: 32px;
  }
  .cd-section__mobile {
    display: none;
  }

  @media screen and (max-width: 990px) {
    .selected-course-sections-hours {
      text-align: center !important;
    }
  }
}
.cd__resume--style {
  margin-right: 12px;
}
[dir="rtl"] {
  .ac-mc__list {
    .ac-mc__list-img {
      margin-right: 4px;
    }
  }

  .cd__modules--style {
    padding-left: 28px;
    padding-right: 4px;
  }
  .cd__effort--style {
    padding-right: 10px;
  }
  .cd__tooltip--text {
    text-align: right;
  }
  .cd__resume--style {
    margin-right: 0;
    margin-left: 12px;
  }
  .cd-tabs-custome-styles {
    .nav {
      &.nav-pills {
        padding-right: 0;
      }
    }
    .nav-item + .nav-item {
      margin-left: 0px;
      margin-right: 16px;
    }
  }
  .cd-main_course-actions {
    margin-left: 0px;
    margin-right: 16px;
  }
  .cd_org_details {
    .cd-org__card_header_r {
      text-align: left;
    }
  }
  .ls-card__footer {
    .ls-card__footer-view img {
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
      padding-left: 5px;
    }
  }
}
</style>

<style lang="scss">
.cd_org_details {
  margin-top: 0.5rem;
  width: 100%;
  .cd_org_card__header_l {
    width: 100%;
    @include h5;
    float: left;
    font-style: normal;
    padding-left: 0px;
  }
  .row {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
  .cd-org__card_header_r {
    display: block;
    width: 100%;
    color: $brand-primary-400;
    @include body-medium;
    text-align: right;
    float: right;
    padding-top: 6px;
    font-style: normal;
    padding-right: 0px;
  }
  .cd_org-card__mobile {
    display: none;
  }

  .cd_org-card {
    width: 100%;
    padding: 0px;
    display: flex;
    margin-top: 1.5rem;
    .ls-card__wrapper {
      background: #ffffff;
      box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
      border-radius: 16px;
      padding: 16px;
      margin-bottom: 16px;

      @include horizontal-space-between;
      &:hover {
        box-shadow: 0px 4px 8px 4px rgba(33, 33, 33, 0.2);
      }
      .ls-card__left {
        img {
          object-fit: contain;
          vertical-align: baseline;
        }
      }
      .ls-card__right {
        padding-left: 16px;
        flex-grow: 1;

        h6 {
          @include body-regular;
        }
        p {
          @include body-medium;
          color: $brand-neutral-300;
          margin-bottom: 12px;
          overflow: hidden;
          @include line-clamp($lines: 2);
          white-space: pre-wrap;
        }
        .ls-card__footer {
          display: flex;
          @include label-medium;
          .ls-card__footer-view {
            @include body-medium;
            font-style: normal;
            padding: 3px 3px 3px 2px;
            color: $brand-primary;
            cursor: pointer;
            img {
              margin-left: 5px;
            }
            &:hover {
              background: $brand-primary-75;
              border-radius: 8px;
              color: $brand-primary-400;
            }
          }
        }
      }
      cursor: pointer;
    }
    .cd_org-col {
      padding: 0 !important;
    }
  }
  .cd_org_terms__mobile {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .cd_org_details {
    .cd_org-card {
      .ls-card__wrapper {
        display: flow;
        .ls-card__right {
          margin-top: 0.5rem;
          padding-left: 0px;
          .ls-card__footer {
            .ls-card__footer-view {
              padding: 0px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1165px) {
  .cd_org_details {
    .cd-org__card_header_r {
      display: none !important;
    }
    .cd_org_terms__mobile {
      width: 100%;
      color: $brand-primary-400;
      @include body-medium;
      font-style: normal;
      display: block !important;
      text-align: center !important;
      margin-top: 10px;
    }
  }
}
</style>

<style lang="scss">
.course__detail--section {
  position: relative;
  width: 100%;
  background-color: $brand-neutral-0;
  .course__bg--wrapper {
    position: relative;
    width: 100%;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% + 174px);
      border-bottom-left-radius: 32px;
      border-bottom-right-radius: 32px;
      background: $radial-gradient-bg;
    }
  }
  .cd__wrapper {
    .cd-top-section,
    .cd-bottom-section {
      font-family: $font-family;
      position: relative;
      z-index: 1;
      background-color: transparent;
      .breadcrumb {
        margin: 0;
        .breadcrumb-item {
          position: relative;
          &:before {
            color: $brand-neutral-0;
            position: absolute;
            top: 2px;
            left: 13px;
            padding: 0;
            margin: 0;
          }
          a {
            @include label-large($brand-neutral-0, 500);
          }
          + .breadcrumb-item {
            padding-left: 32px;
          }
        }
      }
      .cd-main__wrapper {
        background: transparent;
      }
      .cd__title--style {
        color: $brand-neutral-0;
        margin-top: 24px;
        margin-bottom: 0;
      }
      .course-info {
        margin: 24px 0px 0px;
        @include flex-center;
        padding: 0;
        li {
          @include flex-center;
          @include label-large($brand-neutral-0, 500);
          line-height: 16px;
          letter-spacing: 0.2px;
          img {
            margin-right: 4px;
          }
          &:not(:first-child) {
            margin-left: 32px;
          }
          span {
            @include flex-center;
          }
        }
      }
      .cd-video-section {
        border-radius: 16px;
        margin-top: 24px;
        .cd__prg-overview__banner {
          border-radius: 24px;
          position: relative;
          height: 300px;
          width: 100%;
          overflow: hidden;
          .video-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            .play-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .course-video {
            max-width: 100%;
            max-height: 100%;
          }

          .intro_video_url_sm {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .empty-image {
          width: 100%;
          max-height: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .self-paced-calender-icons {
        img {
          filter: invert(35%) sepia(36%) invert(37%) sepia(58%) saturate(5230%)
            hue-rotate(198deg) brightness(104%) contrast(104%);
        }
      }
    }

    .cd-top-section {
      padding: 119px 0px 0px;
      .breadcrumb {
        padding: 0;
      }
    }

    .cd-bottom-section {
      padding: 0px 0px 64px;
      .cd-main__wrapper {
        min-height: 500px;
      }
    }

    .cd__who-stamp--style {
      text-align: left;
      padding-left: 16px;
      padding-top: 5px;
      padding-right: 12px;
    }

    .cd__who-stamp--pre-message {
      @include subtitle-regular;
      color: $brand-neutral-900;
      text-align: left;
      margin-left: 20px;
    }

    .cd__who-stamp--pre-message2 {
      @include body-medium;
      color: $brand-neutral-900;
      padding-top: 10px;
      text-align: left;
      margin-left: 20px;
    }
    .cd__register-text {
      @include subtitle-regular;
      .cd__register--link {
        color: $brand-primary-400;
        text-decoration: underline;
      }
      .cd__start-learning {
        color: $brand-primary;
        margin: 5px;
      }
    }
    .cd-breadcrumb {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;

      .breadcrumb {
        padding-left: 0px;
        background: transparent;

        .breadcrumb-item {
          a {
            color: $brand-inactive-text;
            text-decoration: none;
          }

          &.active {
            color: $brand-primary;
          }
        }

        .breadcrumb-item + .breadcrumb-item::before {
          color: $brand-inactive-text;
          content: "›";
          font-size: 16px !important;
          line-height: 14px;
        }
      }
    }

    .main-title {
      @include h3;
      display: flex;
      align-items: center;
      color: $brand-public-primary;
      word-break: break-word;
    }

    .cd-body-section {
      margin-top: 24px;

      .cd-button__mobile {
        margin-bottom: 32px;
      }

      .cd-section__mobile {
        display: none;
      }
      .start-learning {
        width: 100%;
        height: 56px;
        box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
          0px 1px 3px rgba(0, 0, 0, 0.3);
        border-radius: 100px;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          margin-left: 7px;
        }
      }

      .module-section {
        border: 1px solid $brand-grey-bg;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 10px;
        position: relative;

        .cd-modules__heading {
          text-align: left;
        }

        .module-div {
          height: 300px;
          overflow: hidden;
          text-align: left;

          .cd-content__modules {
            .lx-collapse {
              border: 0px !important;
              background: #f7f8fb !important;

              .lx-collapse__toggle {
                .active {
                  display: none !important;
                }
              }
            }
          }
        }

        .module-section__heading {
          font-size: 22px;
          line-height: 28px;
          display: flex;
          align-items: center;
          color: $brand-public-primary;
          font-family: $font-family;
          font-style: normal;
          font-weight: 500;
          padding-left: 15px;
        }

        .module-footer {
          position: absolute;
          width: 100%;
          top: 85px;
          z-index: 1;
          background: linear-gradient(
            358.46deg,
            #f7f8fb 46.56%,
            rgba(255, 255, 255, 0) 101.18%
          );
          height: 262px;
          left: 0;
          padding-top: 95px;

          .row {
            height: 100%;

            h6 {
              @include subtitle-large;
              line-height: 28px;
              text-align: center;
              color: $brand-public-secondary;
            }
          }
        }
      }
    }

    .cd-fixed-footer-section {
      padding: 24px 32px;
      background-color: $brand-neutral-0;
      box-shadow: 0px -4px 8px 4px rgba(33, 33, 33, 0.1);

      .footer-container {
        max-width: 680px;
        margin: auto;
      }

      .lxp-button {
        &.gradient-bg {
          height: 56px !important;
        }
      }
      .cd__register-text {
        margin-top: 10px;
      }
    }

    @media screen and (max-width: 767px) {
      .cd-top-section,
      .cd-bottom-section {
        margin-top: 0px;
      }

      .module-section {
        padding-bottom: 70px !important;
      }

      .cd-body-section {
        margin-top: 0px;
        .module-footer {
          .module-sec__button {
            width: 70%;
            margin: auto;
          }

          h6 {
            font-size: 20px !important;
          }
        }

        .start-learning {
          margin: 20px;
          width: 70%;

          span {
            font-size: 12px !important;
          }
        }
      }
      .self-paced-calender-icons {
        margin-top: 20px;
        padding-bottom: 24px !important;

        .title,
        .description {
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.2px;
          color: $brand-neutral-700;
          margin-top: 4px;
        }

        .title {
          font-weight: 500;
          margin-top: 6px;
        }
      }
      .cd-fixed-footer-section {
        padding: 24px 32px;
        background-color: $brand-neutral-0;
        box-shadow: 0px -4px 8px 4px rgba(33, 33, 33, 0.1);

        .footer-container {
          max-width: 288px;
        }
      }
    }

    @media screen and (max-width: 512px) {
      .main-title {
        margin-top: 0px;
      }
      .cd__more-learning-programs {
        margin-top: 18px !important ;
      }

      .selected-course-sections-hours {
        text-align: center !important;
        padding-bottom: 12px;
        margin-bottom: 0px;
        .selected-course-sections {
          padding-left: 10px;
          padding-top: 6px;
        }
      }

      .cd-body-section {
        .start-learning {
          margin: 20px auto;
          width: 100%;
        }

        .module-footer {
          .module-sec__button {
            width: 92%;
          }
        }
      }
      .cd__title--resp-style {
        @include h6;
        margin-bottom: 28px;
        text-align: center;
      }

      .more-learning-programs {
        margin-top: 28px;
        padding-top: 48px;

        .more-learning-programs-div {
          margin-top: 25px;
        }
      }
      .cd-tabs-custom-styles {
        .nav-link {
          border-radius: 8px;
          color: $brand-neutral-700;
          @include label-small;
        }
      }
    }
  }
  &.not-found {
    .course__bg--wrapper {
      &:before {
        display: none;
      }
    }
    .cd__wrapper {
      .cd-top-section,
      .cd-bottom-section {
        .breadcrumb {
          .breadcrumb-item {
            a {
              color: $brand-disabled-grey;
            }
            &.active {
              &:before {
                display: none;
              }
              a {
                color: $brand-primary;
              }
            }
            + .breadcrumb-item {
              &:before {
                color: $brand-disabled-grey;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 992px) {
    .cd__wrapper {
      .cd-top-section,
      .cd-bottom-section {
        .course-info {
          li.d-lg-none {
            + li {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .self-paced-calender-icons {
      padding: 24px 16px;
      border-color: #acacac !important;
      .text-center {
        @include flex-center;
        margin: 0;
        flex-direction: row;
        padding: 0px;
        > div {
          @include flex-center;
        }
        .title,
        .description {
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: $brand-neutral-700;
          margin-top: 0;
          margin-left: 4px;
        }
        .title {
          font-weight: 500;
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .cd__wrapper {
      .cd-top-section {
        padding: 88px 0px 0px;
      }
      .cd-bottom-section {
        padding: 0px 0px 0px;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    .course__bg--wrapper {
      &:before {
        height: calc(100% + 99px);
      }
    }
    .cd__wrapper {
      .cd-top-section,
      .cd-bottom-section {
        .breadcrumb {
          .breadcrumb-item {
            padding-left: 0 !important;
            &:first-child,
            &:last-child a,
            &:nth-child(2):before {
              display: none;
            }
            &:last-child {
              height: 21px;
            }
          }
        }
        .cd__title--style {
          @include h6;
          margin-top: 8px;
        }
        .course-info {
          margin-top: 8px;
        }
        .cd-video-section {
          .cd__prg-overview__banner {
            height: 150px;
          }
        }
      }
      .cd-fixed-footer-section {
        padding: 8px 16px;
        .lxp-button.gradient-bg {
          height: 40px !important;
          padding: 0;
        }
        .cd__register-text {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
@media only screen and (min-width: 1200px) {
  .cd-video-section {
    .left-panel {
      width: 827px;
      max-width: 827px;
    }
    .right-panel {
      padding-left: 28px;
    }
  }
}
</style>
